// Dependencies
import React, { useState, useEffect, useRef, useMemo } from "react";
import history from "history/browser";
import axios from "axios";
import { useMapEvents, Marker, Tooltip, Polyline, Circle } from "react-leaflet";
import L from "leaflet";
import styled from "styled-components";

// JS pages
import MarkersNearby from "./MarkersNearby";
import { getPoiNearby } from "./restQueries/getPoiNearby";

// styling
import "leaflet/dist/leaflet.css";
import "../index.css";

//icons
import countries from "../utils/countries.json";
import crosshairRed from "../assets/crosshair_red.png";
import crosshairGreen from "../assets/crosshair_green.png";
import polylineVertice from "../assets/polyline_vertice.svg";
import routeFlag from "../assets/flag.png";

const iconCenterNoFloat = new L.icon({
  iconUrl: crosshairRed,
  iconSize: [40, 40],
  iconAnchor: [20, 20],
  popupAnchor: [-16, -16],
});

const iconCenterFloat = new L.icon({
  iconUrl: crosshairGreen,
  iconSize: [40, 40],
  iconAnchor: [20, 20],
  popupAnchor: [-16, -16],
});

const iconPolylineVertice = new L.icon({
  iconUrl: polylineVertice,
  iconSize: [20, 20],
  iconAnchor: [10, 10],
  popupAnchor: [-16, -16],
});

const iconRouteFlag = new L.icon({
  iconUrl: routeFlag,
  iconSize: [30, 36],
  iconAnchor: [0, 36],
  popupAnchor: [-16, -16],
});

const StyledTooltip = styled(Tooltip)`
  background-color: #eee;
  color: #000;
  border-radius: 10px;
  overflow-wrap: break-word;
  overflow: hidden;
`;

export default function LocationMarker({
  pois,
  coordsHome,
  selectedMarker,
  coordsFromHomePage,
  coordsFromMytrips,
  category,
  activity,
  limit,
  closePopup,
  floatMode,
  parentCallback,
  sendDetailsToParent,
  sendPositionToParent,
  sendFromToParent,
  sendHomeStateToParent,
  sendLimitToParent,
  loading,
  reset,
  closePopupCB,
  errorMsg,
  searchMode,
  countLineMarkers,
  KMLInput,
  openPane,
}) {
  const [centerAddress, setCenterAddress] = useState(null);
  const [poiNearby, setPoiNearby] = useState([]);
  const [count, setCount] = useState(0);
  const [hasHistory, setHasHistory] = useState(false);
  const [mousePos, setMousePos] = useState({ lat: 0, lng: 0 });
  const [route, setRoute] = useState([]);
  const [points, setPoints] = useState([]);
  const [hasResult, setHasResult] = useState({ res: "" });

  const markerRef = useRef(null);
  let location = history.location;

  useEffect(() => {
    countLineMarkers(points.length);
  }, [points]);

  useEffect(() => {
    if (searchMode) {
      setPoints([]);
      setHasResult({ res: "" });
    }
  }, [searchMode]);

  useEffect(() => {
    if (limit) {
      setHasHistory(false);
    }
  }, [limit]);

  useEffect(() => {
    if (coordsFromHomePage) {
      setPoints([coordsFromHomePage]);
      getPoisNearMarker(coordsFromHomePage);
    }
  }, [coordsFromHomePage]);

  useEffect(() => {
    if (location.state && !coordsFromHomePage && !coordsHome) {
      setHasHistory(true);
      setPoiNearby(location.state.some);
      parentCallback(location.state.some);
      sendLimitToParent(location.state.limit);
      sendPositionToParent(location.state.centertest);

      if (location.state.active !== null) {
        const idFromHistory = location.state.active;
        getIdFromHistory(idFromHistory);
      }
    }
  }, []);

  useEffect(() => {
    // clean up after refresh page
    window.addEventListener("beforeunload", function () {
      history.replace();
    });
  }, []);

  useEffect(() => {
    if (coordsHome) {
      localStorage.removeItem("geoResponse");
      new Promise(function (resolve, reject) {
        var locRequest =
          process.env.REACT_APP_PREFIX +
          "www.mapquestapi.com/geocoding/v1/reverse?key=" +
          process.env.REACT_APP_GEOCODING +
          "&location=" +
          coordsHome.lat +
          "," +
          coordsHome.lng +
          "&includeRoadMetadata=true&includeNearestIntersection=true";

        fetch(locRequest)
          .then((response) => response.json())
          .then((result) => {
            const { adminArea1, adminArea3, adminArea4, adminArea5 } =
              result.results[0].locations[0];
            const city = adminArea5;
            const county = adminArea4;
            const state = adminArea3;
            const country = countries.countries[adminArea1];
            const geoString =
              (city ? city + ", " : "") +
              (county ? county + ", " : "") +
              (state ? state + ", " : "") +
              country;
            setCenterAddress(geoString);
            sendFromToParent(geoString);
          })
          .catch(function (err) {
            console.error("Error: ", err);
          });
      });

      getPoiNearby(coordsHome, category, activity, limit).then((promise) => {
        const status = promise.status;
        if (status === 200) {
          setPoiNearby(promise.data);
          parentCallback(promise.data);
          sendDetailsToParent(null);
          sendHomeStateToParent(false);
          setHasResult({ res: "marker" });
        }
      });

      sendPositionToParent(coordsHome);
    } else if (coordsFromMytrips) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/mytrips`, {
          params: {
            ids: coordsFromMytrips,
          },
        })
        .then((promise) => {
          const status = promise.status;
          if (status === 200) {
            setPoiNearby(promise.data);
            parentCallback(promise.data);
            sendDetailsToParent(null);
          }
        });
    } else if (!hasHistory && !coordsHome) {
      if (!floatMode && points.length === 1 && searchMode === "marker") {
      }
    }
  }, [
    coordsFromMytrips,
    coordsFromHomePage,
    coordsHome,
    category,
    activity,
    limit,
    searchMode,
    points,
  ]);

  useEffect(() => {
    if (searchMode === "marker" && hasResult.res === "marker") {
      getPoisNearMarker(points[0]);
    } else if (searchMode === "polyline" && hasResult.res === "polyline") {
      getPoisNearPolyline(points);
    } else if (
      (searchMode === "car" || searchMode === "bicycle" || searchMode === "pedestrian") &&
      (hasResult.res === "car" || hasResult.res === "bicycle" || hasResult.res === "pedestrian")
    ) {
      getPoisNearRoute(points, hasResult.res);
    } else if (searchMode === "circle" && hasResult.res === "circle") {
      getPoisWithinCircle(points[0], calcDistance(points[0], points[1]));
    } else if (hasResult.res === "kml") {
      getKMLResult(KMLInput);
    }
  }, [limit, category, activity, searchMode]);

  const getPoisNearMarker = (p) => {
    if (!floatMode) {
      loading(true);

      getPoiNearby(p, category, activity, limit).then((promise) => {
        const status = promise.status;
        if (status === 200) {
          setPoiNearby(promise.data);
          parentCallback(promise.data);
          sendDetailsToParent(null);
          sendHomeStateToParent(false);
          setHasResult({ res: "marker" });
          loading(false);
        }
      });
    }
  };

  const getPoisNearPolyline = (p) => {
    loading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/poisnearpolyline`, {
        params: {
          latlngs: p,
          limit: limit,
          category: category,
          activity: activity,
        },
      })
      .then((result) => {
        setPoiNearby(result.data.result);
        parentCallback(result.data.result);
        sendPositionToParent(null);
        setHasResult({ res: "polyline" });
        loading(false);
      })
      .catch((err) => {
        console.error("error: ", err);
        loading(false);
      });
  };

  const getPoisNearRoute = (p, transport) => {
    loading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/poisnearroute`, {
        params: {
          fromLat: p[0].lat,
          fromLng: p[0].lng,
          toLat: p[1].lat,
          toLng: p[1].lng,
          transport: transport,
          limit: limit,
          category: category,
          activity: activity,
        },
      })
      .then((result) => {
        setRoute(result.data.polyline);
        setPoiNearby(result.data.poiNearby);
        parentCallback(result.data.poiNearby);
        sendPositionToParent(null);
        setHasResult({ res: transport });
        loading(false);
      })
      .catch((err) => {
        console.error("error: ", err);
        loading(false);
      });
  };

  const getPoisWithinCircle = (center, r) => {
    loading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/poiswithincircle`, {
        params: {
          lat: center.lat,
          lng: center.lng,
          radius: r,
          category: category,
          activity: activity,
        },
      })
      .then((result) => {
        if (typeof result.data.result != "string") {
          sendPositionToParent({ lat: center.lat, lng: center.lng });
          setPoiNearby(result.data.result);
          parentCallback(result.data.result);
          setHasResult({ res: "circle" });
        } else {
          errorMsg("Too many items! Reduce search radius");
          console.log("too many items");
        }

        loading(false);
      })
      .catch((err) => {
        console.error("error: ", err);
        loading(false);
      });
  };

  const getKMLResult = (file) => {
    loading(true);
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onloadend = async (evt) => {
      const readerData = evt.target.result;
      const parser = new DOMParser();
      const xml = parser.parseFromString(readerData, "text/xml");
      const xmlParsed = new XMLSerializer().serializeToString(xml.documentElement);
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/poiskml`,
        method: "POST",
        data: {
          file: xmlParsed,
          limit: limit,
          category: category,
          activity: activity,
        },
      });
      setPoiNearby(res.data.poiNearby);
      parentCallback(res.data.poiNearby);
      setRoute(res.data.polyline);
      loading(false);
    };
  };

  const handlePointClick = (marker) => {
    if (marker === points.length - 1) {
      loading(true);
      getPoisNearPolyline(points);
    }
  };

  useMapEvents({
    mousemove: (event) => {
      if (searchMode === "polyline" && hasResult.res === "" && points.length > 0) {
        setMousePos(event.latlng);
      }
      if (searchMode === "circle" && points.length < 2) {
        setMousePos(event.latlng);
      } else if (searchMode === "circle" && points.length >= 2) {
        setMousePos(points[1]);
      }
    },
  });

  useMapEvents({
    click(e) {
      if (searchMode === "marker" && points.length >= 1) {
        setHasResult({ res: "" });
        setPoints([]);
        reset();
      }

      if (searchMode === "circle" && points.length >= 2) {
        setHasResult({ res: "" });
        setPoints([]);
        reset();
      }

      if (searchMode === "polyline" && points.length > 0 && hasResult.res === "polyline") {
        setHasResult({ res: "" });
        setPoints([]);
        reset();
      }

      if (
        (searchMode === "car" || searchMode === "bicycle" || searchMode === "pedestrian") &&
        points.length === 2
      ) {
        setPoints([]);
        setHasResult({ res: "" });
        reset();
      }

      const newPoint = { lat: e.latlng.lat, lng: e.latlng.lng };
      setPoints((arr) => [...arr, newPoint]);

      if (searchMode === "marker") {
        setCount(0);
        loading(true);

        axios
          .get(`${process.env.REACT_APP_API_URL}/api/reversegeocode`, {
            params: {
              lat: newPoint.lat,
              lng: newPoint.lng,
            },
          })
          .then((result) => {
            const geoString = result.data.result !== "undefined" ? result.data.result : "water";
            setCenterAddress(geoString);
            sendFromToParent(geoString);
          })
          .catch((err) => {
            console.error("error: ", err);
          });

        sendPositionToParent(e.latlng);

        getPoisNearMarker([newPoint.lat, newPoint.lng]);
      } else if (searchMode === "polyline") {
        setMousePos({ lat: newPoint.lat, lng: newPoint.lng });
      } else if (searchMode === "car" || searchMode === "bicycle" || searchMode === "pedestrian") {
        if (points.length === 1) {
          getPoisNearRoute([points[0], newPoint], searchMode);
        }
      } else if (searchMode === "circle") {
        if (points.length === 1) {
          getPoisWithinCircle(points[0], calcDistance(points[0], newPoint));
        }
      }
    }
  });

  const calcDistance = (start, end) => {
    var lon1 = toRadian(start.lng),
      lat1 = toRadian(start.lat),
      lon2 = toRadian(end.lng),
      lat2 = toRadian(end.lat);

    var deltaLat = lat2 - lat1;
    var deltaLon = lon2 - lon1;

    var a =
      Math.pow(Math.sin(deltaLat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
    var c = 2 * Math.asin(Math.sqrt(a));
    var EARTH_RADIUS = 6371;
    var dist = c * EARTH_RADIUS * 1000;

    return dist;

    function toRadian(degree) {
      return (degree * Math.PI) / 180;
    }
  };

  const getCoords = (c) => {
    var centerArray = [poiNearby[c].lat, poiNearby[c].lng];
    return centerArray;
  };

  useEffect(() => {
    setPoiNearby(pois);
  }, [pois, selectedMarker]);

  useEffect(() => {
    if (location.state) {
      const oldData = location.state;
      oldData.active = selectedMarker;
      history.push("/map", oldData);
    }
  }, [selectedMarker]);

  const getIdFromHistory = (childData) => {
    const arr = location.state.some;
    const selectedDetails = arr.find((selected) => selected.id === childData);
    sendDetailsToParent(selectedDetails);
  };

  const getId = (childData) => {
    const selectedDetails = poiNearby.find((selected) => selected.id === childData);
    sendDetailsToParent(selectedDetails);
  };

  const openPaneB = (c) => {
    const selectedDetails = poiNearby.find((selected) => selected.id === c);
    openPane(selectedDetails);
  };

  useEffect(() => {
    let counter = count;
    const interval =
      poiNearby &&
      setInterval(() => {
        if (counter >= poiNearby.length) {
          clearInterval(interval);
        } else {
          setCount(!floatMode ? (count) => count + 1 : poiNearby.length);
          counter++;
        }
      }, 50);

    return () => clearInterval(interval);
  }, [poiNearby]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        setPoints([]);
        const marker = markerRef.current;
        if (marker != null) {
          setHasHistory(false);
          sendLimitToParent(10);
          setCount(0);

          const newDragCenter = { lat: marker.getLatLng().lat, lng: marker.getLatLng().lng };

          axios
            .get(`${process.env.REACT_APP_API_URL}/api/reversegeocode`, {
              params: {
                lat: newDragCenter.lat,
                lng: newDragCenter.lng,
              },
            })
            .then((result) => {
              const geoString = result.data.result !== "undefined" ? result.data.result : "water";

              setCenterAddress(geoString);
              sendFromToParent(geoString);
              setPoints([newDragCenter]);
            })
            .catch((err) => {
              console.error("error: ", err);
            });

          sendPositionToParent(newDragCenter);
        }
      },
    }),
    [floatMode]
  );

  return (
    <div>
      {(searchMode === "marker" || searchMode === "circle") && points.length > 0 && (
        <Marker
          position={points[0]}
          draggable={true}
          ref={markerRef}
          eventHandlers={eventHandlers}
          icon={floatMode ? iconCenterFloat : iconCenterNoFloat}>
          {
            <StyledTooltip sticky opacity={1} direction="top" offset={[0, -20]}>
              <h5 style={{ fontSize: "14px", fontWeight: "bold" }}>{centerAddress}</h5>
            </StyledTooltip>
          }
        </Marker>
      )}
      {points.length !== 0 && searchMode === "polyline"
        ? points.map((idx, i) => {
            return (
              <Marker
                key={i}
                position={[idx.lat, idx.lng]}
                icon={iconPolylineVertice}
                eventHandlers={{ click: (e) => handlePointClick(i) }}></Marker>
            );
          })
        : null}
      {points.length !== 0 &&
      (searchMode === "car" || searchMode === "bicycle" || searchMode === "pedestrian")
        ? points.map((idx, i) => {
            return (
              <Marker
                key={i}
                position={[idx.lat, idx.lng]}
                icon={iconRouteFlag}
                eventHandlers={{ click: (e) => handlePointClick(i) }}></Marker>
            );
          })
        : null}
      {searchMode === "polyline" && (
        <Polyline
          pathOptions={{ color: "#AD4646" }}
          positions={points.map((a) => [a.lat, a.lng])}
        />
      )}
      {searchMode === "polyline" && points.length !== 0 && hasResult.res !== "polyline" ? (
        <Polyline
          pathOptions={{ color: "#AD4646", dashArray: "10, 10" }}
          positions={[
            [points[points.length - 1].lat, points[points.length - 1].lng],
            [mousePos.lat, mousePos.lng],
          ]}
        />
      ) : null}

      {route && <Polyline dashArray={"4, 4"} dashOffset={"4"} positions={route} />}
      {searchMode === "circle" && points.length >= 1 && points.length <= 3 ? (
        <Circle
          center={[points[0].lat, points[0].lng]}
          interactive={false}
          pathOptions={{ fillColor: "transparent", color: "#c2c2c0" }}
          radius={calcDistance(points[0], mousePos)}
        />
      ) : null}
      {poiNearby &&
        poiNearby.slice(0, floatMode ? poiNearby.length : count).map((idx, i) => (
          <div key={i}>
            <MarkersNearby
              coords={getCoords(i)}
              image={idx.img_url_thumb ? idx.img_url_thumb : idx.formats.thumbnail.url}
              data={idx}
              selectedIndex={idx.id}
              openPopup={selectedMarker}
              closePopup={closePopup}
              closePopupCB={(c) => closePopupCB(c)}
              sendIdToParent={(childData) => getId(childData)}
              openPaneBWithLink={(c) => openPaneB(c)}
            />
          </div>
        ))}
      {coordsFromMytrips && poiNearby
        ? poiNearby
            .slice(0, count)
            .map((idx, i) => (
              <MarkersNearby
                key={i}
                coords={[idx.lat, idx.lng]}
                data={idx}
                image={idx.img_url_thumb ? idx.img_url_thumb : idx.formats.thumbnail.url}
                category={idx.category_sin}
                title={idx.title}
                selectedIndex={idx.id}
                openPopup={selectedMarker}
                sendIdToParent={(childData) => getId(childData)}
                openPaneBWithLink={(c) => openPaneB(c)}
              />
            ))
        : null}
    </div>
  );
}
