// Dependencies
import React from "react";
import { Routes, Route, Link, NavLink, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";

// JS pages
import client from "../utils/apolloClient";
import { useCurrentUser, useDispatchCurrentUser } from "./CurrentUser";
import Home from "./Home";
import Article from "./OneTripdata";
import ShowMap from "./ShowMap";
import ShowMapMobile from "./ShowMapMobile";
import Dashboard from "../private_pages/MyTrips";
import UserProfile from "../private_pages/UserProfile";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import HowItWorks from "../public_pages/HowItWorks";
import Donate from "../public_pages/Donate";
import TermsofUse from "../public_pages/TermsOfUse";
import Privacy from "../public_pages/Privacy";
import ReleaseNotes from "../public_pages/ReleaseNotes";
import NotFound from "../public_pages/NotFound";
import useWindowDimensions from "../utils/useWindowDimensions";

// styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeEurope,
  faInfoCircle,
  faHeart,
  faUserPlus,
  faTable,
  faWrench,
  faArrowAltCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

// assets
import hamburger from "../assets/menu.svg";
import loginIcon from "../assets/login.svg";
import { useEffect } from "react";

function PrivateRoute({ children, ...rest }) {
  const currentUser = useCurrentUser();

  return currentUser.username ? children : <Navigate to="/login" />;
}

function App() {
  // init
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useDispatchCurrentUser();
  const user = useCurrentUser();

  const handleLogout = async () => {
    await axios.get(`/api/users/logout`);
    client.cache.reset();
    localStorage.clear();
    dispatch({ type: "LOGOUT", user: null });
    navigate("/login");
  };

  const activeColor = (val) => {
    if (val) {
      return { color: "#e5ff44" };
    } else {
      return { color: "#fff" };
    }
  };

  return (
    <>
      <div className="App">
        <div className="header">
          <div className="leftmenu">
            <div className="menu-item-outerleft">
              <NavLink
                className="menu-item"
                id={"planner-item"}
                to="/map"
                style={({ isActive }) => activeColor(isActive)}>
                <FontAwesomeIcon className="icon-solid" icon={faGlobeEurope}></FontAwesomeIcon>
                <span>MAP</span>
              </NavLink>
            </div>
          </div>
          <div className="logo-container">
            <Link to="/">
              {" "}
              <div className="logo"></div>
            </Link>
          </div>
          <div className="rightmenu">
            <div className="menu-item-outerright">
              <NavLink
                to="/howitworks"
                className="menu-item"
                id={"info-item"}
                style={({ isActive }) => activeColor(isActive)}>
                <FontAwesomeIcon className="icon-solid" icon={faInfoCircle}></FontAwesomeIcon>
                <span>how it works</span>
              </NavLink>
            </div>
            <div className="menu-item-outerright">
              <NavLink
                to="/donate"
                className="menu-item"
                id={"donate-item"}
                style={({ isActive }) => activeColor(isActive)}>
                <FontAwesomeIcon className="icon-solid" icon={faHeart}></FontAwesomeIcon>
                <span>donate</span>
              </NavLink>
            </div>
            
          </div>
          {/* <div className="login-section">
            {!user.isAuthenticated ? (
              <NavLink
                to="/login"
                className="menu-container"
                style={({ isActive }) => activeColor(isActive)}>
                <img id="login-item" src={loginIcon} alt="login" width={30} height={30} />
              </NavLink>
            ) : (
              <div className="menu-container">
                <img id="hamburger-item" src={hamburger} alt="menu" width={30} height={30} />
                <div id="user-menu">
                  <div>
                    <NavLink
                      to="/user"
                      className="menu-item sub"
                      id={"dashboard-item"}
                      style={({ isActive }) => activeColor(isActive)}>
                      <FontAwesomeIcon className="icon-solid" icon={faTable}></FontAwesomeIcon>
                      <span>Planner</span>
                    </NavLink>

                    <NavLink
                      to="/profile"
                      className="menu-item sub"
                      id={"profile-item"}
                      style={({ isActive }) => activeColor(isActive)}>
                      <FontAwesomeIcon className="icon-solid" icon={faWrench}></FontAwesomeIcon>
                      <span>Profile</span>
                    </NavLink>
                    <div className="menu-item sub" onClick={handleLogout}>
                      <FontAwesomeIcon
                        className="icon-solid"
                        icon={faArrowAltCircleLeft}></FontAwesomeIcon>
                      <span>Log out</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/map/:slug" element={width < 1120 ? <ShowMapMobile /> : <ShowMap />} />
          {   <Route exact path="/map" element={width < 1120 ? <ShowMapMobile /> : <ShowMap />} />  }
          <Route path="/article/:Slug" element={<Article />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/howitworks" element={<HowItWorks />} />
          <Route path="/termsofuse" element={<TermsofUse />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/release-notes" element={<ReleaseNotes />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/user"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
